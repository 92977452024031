<template>
  <v-card
    class="mx-auto info-card"
    elevation="9"
    width="65%"
    color="#f6f4f5"
  >
    <v-toolbar
      flat
      color="#f6f4f5"
    >

      <v-btn 
        @click="volver()"
        icon
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-toolbar>
      
    <v-avatar
      class="v-avatar--offset mx-auto mb-n10"
      width="80%"
      height="40%"
      rounded
    >
      <v-img 
        :src="areaActual.ref"
        contain
      />
    </v-avatar>

    <v-card-text class="pt-0">
      <div class="text-h6 mb-2">
        {{areaActual.nombre}}
      </div>

      <div v-if="areaActual.detalle == 'Mining'">
        <p id="parrafos" class="mx-5"></p>
        <v-divider class="my-2"></v-divider>
        <v-expansion-panels multiple>
          <v-expansion-panel >
            <v-expansion-panel-header color="#f6f4f5"><strong> Mining </strong></v-expansion-panel-header>
            <v-expansion-panel-content color="#f6f4f5">
              <div id="parrafos">
                <p>The mining industry is today one of the main industrial activities in our country. It is known that in recent years the profitability and productivity of the mining business has been progressively decreasing due to the decline in market prices and mineral grades, added to high costs in inputs such as energy and increases in wages, for cite the most relevant ones.</p>
                <p>It is a consensus opinion in the industry that -assuming the reality of lower income-, should redouble their efforts to reduce this gap, taking actions to optimize the processes, decrease production costs and operational costs and increase the rates of asset productivity.</p>
                <p>One of the alternatives to improve the productivity of assets is to improve the indexes of reliability in such a way as to improve equipment availability, decrease time between failure, and minimize repair times, which ultimately results in better and better greater use of the asset (higher productivity) and also in the reduction of costs of maintenance.</p>
                <p>In this direction, mining companies develop continuous improvement programs and maintenance plans. Improvements in this area are very significant, particularly considering studies by specialized consulting firms that indicate that an estimate of the annual maintenance cost in large-scale mining varies between 20% and 50% of total production costs. Specialized studies indicate that improvements in 1% of the availability of mining assets can result in profit gains of between 1.7 and 3.5%, which makes it very attractive for the industry to invest to improve availability.</p>
                <p>The opportunity arises from the fact that currently reliability management is focused on the RCM methodology (“Reliability Centered Maintenance”), which proposes maintenance plans and programs based on the management and analysis of availability indicators, local, for each team. The analysis and management of availability indicators that are currently carried out is static, not systematized or automated, and highly dependent on the knowledge and experience of those in charge. On the other hand, this type of maintenance scheduling is frequently found with the occurrence of unscheduled, unforeseen stoppages, the product of failures or stoppages associated with recommendations arising from the process of monitoring operational conditions. The occurrence of these stoppages alters maintenance planning, makes it less efficient, and directly affects the availability of equipment.</p>
                <p>Given the above, having a more precise and accurate prediction mechanism for the key availability indicators would allow for a more efficient adjustment of maintenance planning activities, anticipating conditions of potential risk of service interruption and, consequently, increasing availability. of the teams. The solution proposed in this project is precisely aimed at developing an accurate prediction tool for equipment availability indicators. This prediction will be made from planned maintenance variables, and also complemented with other variables or operational conditions.</p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel >
            <v-expansion-panel-header color="#f6f4f5"><strong> Manufacturing </strong></v-expansion-panel-header>
            <v-expansion-panel-content color="#f6f4f5">
              <div id="parrafos">
                <p>The main objective is the development of solutions based on computational intelligence, for predicting the value or status of relevant variables associated with the production process and / or the maintenance process, based on process and / or operation variables. These predictions are useful to improve or make the equipment or the production line more reliable or available (in the case of maintenance) or the quality of the process results (in the case of the production process) more predictable.</p>
                <p>The following cases are considered:</p>
                <p>1. Prediction of paint quality in Coil paint lines.</p>
                <p>All the variables and parameters of the process are mutually interdependent and their effect on the painting result is difficult to analytically model or deterministically predictable. Consequently, its determination is currently made based on the experience of the operators and through trial and error actions, which implies high rates of loss of products that do not meet the minimum quality parameters in painting. The implementation of a result prediction mechanism will allow better adjustment of the process variables, and ultimately reduce painting errors and the consequent economic loss.</p>
                <p>The prediction is achieved from the variables and parameters of the process, which have various huetolerance ranges (types of paint, density, part size, number of parts, process time or others that are relevant), in order to Allow predicting, prior to the start of the production process, the combined result of a certain configuration of these variables, and thereby supporting their adjustment, which ultimately achieve a quality in the painting (output) within the acceptance ranges.</p>
                <p>2. Prediction of availability of paint coil lines.</p>
                <p>The availability of the production line as a whole depends on multiple factors, both operational and production. Predicting the availability of a line provides an important tool for production planning. Prediction of availability is achieved from variables and operating parameters (number of operations, type of products or operation, amount of production, operating time, operator, etc.), and maintenance parameters (failure events, stoppages schedules, downtimes, repair times, etc.). The objective of the solution will be to predict the future availability of the line as a whole, within a certain period (days, weeks or other), and to deliver this forecast on a regular basis.</p>
                <p>3. Prediction of equipment failures of Coil Painting Lines.</p>
                <p>The production processes of the plant use various equipment and machine tools that operate with a high rate of use. Stopping any of them generates a decrease in availability that reduces the efficiency of the production process as a whole (decreases production), and if these stops also occur as a result of failures, it implies higher costs or losses and makes the process more inefficient. To reduce the probability of failures, preventive maintenance is scheduled, which is based on the simple analysis of local statistics for each piece of equipment, and on fixed criteria and estimates of probability of failure that are highly dependent on the knowledge and experience of those in charge.</p>
                <p>Although the predictive model to be developed is dependent on the type of equipment and the type of use to which it is subjected, this model can be parameterized to be used with other inputs corresponding to different equipment. Consequently, it can be inferred that the solution can be applied several times, for different types of equipment, adjusting and adapting its parameters for each type of particular equipment. This opens up in the future, when there is more experience in the real and productive application of the solution, a new possibility of a family of products that are more specific and adjusted to the operational reality of each team.</p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div v-else-if="areaActual.detalle == 'Breast cancer'">

        <p id="parrafos" class="mx-5">Biomedical image applications present great challenges for machine learning. In this area, our group focuses on a variety of contemporary problems that emerge in microscopic images related to male infertility and breast cancer biopsies.</p>
        <v-divider class="my-2"></v-divider>
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header color="#f6f4f5"><strong> Male infertility </strong></v-expansion-panel-header>
            <v-expansion-panel-content color="#f6f4f5">
              <p id="parrafos">Human infertility is the inability of humans to reproduce due to problems associated with various factors. Recent studies indicate that 15% of infertile men worldwide and 30% of them are due to problems related to semen quality. Traditional methods to assess the quality of a patient's semen consist mainly of visual analysis of the sperm smear, seeking to measure parameters such as concentration, motility, vitality, and sperm DNA fragmentation. Among these factors, sperm morphology is considered the most important technical challenge for andrology laboratories since there is much variability among the technicians who perform it. In this sense, visual analysis of sperm morphology still presents a challenge in reproducibility and objectivity. Therefore, having technological support that automatically and accurately performs this type of measurement and offers objective criteria would be advantageous. Furthermore, since sperm cell automatic classification rates according to morphological defects do not show acceptable accuracy for clinical use, an alternative would be to improve segmentation quality before classifying. Therefore, it would be essential to focus efforts on developing methods to improve the accuracy of sperm part segmentation as a crucial step before classification.</p>
              <p id="parrafos">New deep learning architectures for image segmentation are very efficient and accurate in microscopic image segmentation problems. Thus, the great potential of these techniques for biomedical image analysis lies in their speed and efficiency. However, the datasets to validate the effectiveness and efficiency of these promising methods are very small due to the need for extensive annotations at the pixel level by expert andrologists, which leads to a lack of massive deployment in the clinical practice of computer-assisted systems for morphological sperm morphology. To overcome this problem, we focus our research in multiple directions: to investigate and design a data augmentation strategy to extend public datasets for segmentation and morphological classification of sperm cells, to optimize the architecture of well-known segmentation deep models to fit with small datasets, to evaluate the impact of non-local features on segmentation deep learning models using transfer learning, and to investigate and design a multiple instance learning-based (MIL) deep learning system that uses the few expert labels and the reported ones as labels for training, thereby avoiding expensive and time-consuming pixel-wise manual annotations.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#f6f4f5"><strong> Breast cancer </strong></v-expansion-panel-header>
            <v-expansion-panel-content color="#f6f4f5">
              <div id="parrafos">
                <p>Breast cancer is one of the most common cancer in women and the second leading cause of death worldwide. For personalized treatments, the expression of biomarkers such as HER2 is evaluated, estimating intensity and integrity of the membrane cells' staining and scoring the biopsy as 0, 1+, 2+, or 3+, through visual examination under a microscope. Thus, HER2 assessment is performed based on a subjective decision that depends on the experience and interpretation of the pathologist. This subjective decision could lead to pathologist's inter and intra-variability, which are significantly high for breast cancer diagnosis. </p>
                <p>Automatic HER2 scoring would be a possibility to overcome the existing problems in manual procedure because they are not prone to subjective bias and can provide precise quantitative analysis, assisting the expert pathologist to reach a reproducible score. On the other hand, whole slide imaging (WSI) use is increasing in pathology departments worldwide. Various studies suggested that WSI can be adopted in breast pathology as pathologists' performance in reading breast slides while using WSI platforms was comparable to conventional microscopy.</p>
                <p>Additionally, in the last years, deep learning techniques have raised the possibility for fully automatic diagnosis. However, despite decades of research on computer-assisted HER2 assessment, there are still no standard ways of comparing the results achieved with different methods. Instead, it seems that each research group has its dataset of images with one or two expert-generated classifications.</p>
                <p>In this sense, to tackle the above problems, the research is focused on multiple paths:</p>
                <ul>
                  <li>Generating a public gold-standard with the consensus opinion of expert pathologists in cases of HER2 breast cancer biopsies from Chilean laboratories using data augmentation techniques.</li>
                  <br/>
                  <li>Evaluating the performance of deep learning models to score HER2 in breast cancer cases using the generated gold-standard dataset.</li>
                  <br/>
                  <li>Using slide-level diagnostics to train a classification model in a weakly supervised manner instead of expert annotation for supervised learning, which is time-prohibitive at scale. In this sense, if the slide is negative, all its patches must also be negative and not contain a tumor. Conversely, if the slide is positive, it must be true that at least one of all possible tiles contains a tumor. This formalization of the WSI classification problem is an example of the general standard multiple instance assumptions. Therefore, a second approach is self-training and co-training for weakly supervised WSI classification models trained under multiple instance learning (MIL) assumption variants.</li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>

      </div>

      <div v-else-if="areaActual.detalle == 'Neuroscience'">
        <p id="parrafos" class="mx-5"> The last decades have seen a growing interest in neuroscience, with huge brain research initiatives launched in the US and Europe. However, we are still quite far from completely understanding how the brain works. Experimental efforts in neuroscience have resulted in a great amount of data to analyze, which has open opportunities for machine learning approaches to contribute to the understanding of brain function. We focus on two research topics: brain-machine interfaces and  neural modeling. </p>
        <v-divider class="my-2"></v-divider>
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header color="#f6f4f5"><strong> Brain-machine interfaces </strong></v-expansion-panel-header>
            <v-expansion-panel-content color="#f6f4f5">
              <p id="parrafos"> A brain-machine interface (BMI) provides a communication channel between the electrical activity of neurons in the brain and external devices, and can be used to restore, augment or assist human sensorimotor behavior. For example, BMIs have been used to mind-control robotic arms to assist people with disabilities. In the BMI paradigm, the electrical activity of neurons is recorded using implanted or surface electrodes, and then this activity is decoded on- or off-line to perform an action with a computer or other device. Under this paradigm, decoding brain signals requires training a model of, for example, limb movement using neural activity in the form of action potentials or spikes, for the case of invasive BMIs, or EEG signal, for non-invasive approaches. We are developing deep learning (DL) algorithms to improve neural-activity decoding using publicly available databases of brain signals recorded in animals.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#f6f4f5"><strong> Biophysical models of neurons </strong></v-expansion-panel-header>
            <v-expansion-panel-content color="#f6f4f5">
              <p id="parrafos"> The Hodgkin and Huxley (HH) equations  describe mathematically the electrical behavior of neurons. The HH model uses the electrical properties of the cell membrane to calculate the highly nonlinear dynamics of the transmembrane potential. HH models have had a tremendous impact in biomedical research. For example, nerve fiber responses to electrical stimulation applied with implanted or surface electrodes, can be estimated using a two-step approach in which the electric field generated by the electrode(s) is calculated and then applied to a cable model of a fiber to measure its responses. However, one major difficulty of HH-based models is the complexity and computational costs of model evaluation. Biological and other nonlinear dynamical systems with unknown governing differential equations can be described using artificial neural networks (ANN), which, in contrast with biophysical models like HH, rely on the availability of huge amounts of data, and are in general less interpretable. Despite efforts to reduce the numerical burden of solving HH equations, the high dimensionality and complexity of HH-based models of nerve fibers and neurons can hinder the improvement and optimization of electrical stimulation therapies. We hypothesize that electrical stimulation therapies can be modeled with reduced-dimensionality HH models that incorporate structural, dynamical and morphological state variables, and alternatively, using ANNs trained with deep learning techniques.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

    </v-card-text>
  </v-card>
</template>

<script>


export default{
  name:'Area',
  data () {
    return {
      areaActual: Object,
      areas: [
          {
            nombre: 'Mining and Manufacturing Applications',
            detalle: 'Mining',
            ref: require('../assets/Area/mineria.svg')
          },
          {
            nombre: 'Biomedical Image Processing and Classification',
            detalle: 'Breast cancer',
            ref: require('../assets/Area/imagenes.svg')
          },
          {
            nombre: 'Neuroscience',
            detalle: 'Neuroscience',
            ref: require('../assets/Area/neurociencia.svg')
          }
        ],
    }
  },
  methods:{
    volver(){
      this.$router.go(-1)
    },
    cargarArea(nombre){
      for(let i = 0; i < this.areas.length ; i++){
        if (this.areas[i].nombre == nombre.split('_').join(' ')){
          this.areaActual = this.areas[i]
        }
      }

      return 0;
    
    }
  },

  created (){
    this.cargarArea(this.$route.params.area)
  }
  
}

</script>

<style scoped>
  .margen{
    margin-bottom:132px;
  }
  .v-avatar--offset {
    top: -100px;
    position: relative;
  }
  #parrafos {
    text-align: justify;
  }
  .info-card{
    margin:64px auto 132px;
    width:65%;
    opacity: 0;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @keyframes slide-top {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>